import {useLocation, useNavigate} from "react-router-dom";


const Splash = () => {
    const navigate = useNavigate()
    const location = useLocation()

    if (location.state){
        localStorage.clear()
        navigate("/login")
    }

    if (localStorage.getItem("token") != null){
        let s = setInterval(() => {
                navigate("/home");
                clearInterval(s)
            },5000
        );
    } else {
        let s = setInterval(() => {
                navigate("/login");
                clearInterval(s)
            },5000
        );
    }

    return (
        <div className="RootView">
            <div className="RootWidth">
                <div className="SplashDiv paymentStatusDiv">
                    <img className="logoIcon" alt="" src="../images/success_img.png" style={{marginBottom:"20px"}}  />
                    <p className="phead">Payment Transaction Successful</p>
                    <p className="psub">will redirect back automatically in 5 secs...</p>
                </div>
            </div>
        </div>
    );
};

export default Splash;
