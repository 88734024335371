

const reducer = (state= localStorage.hasOwnProperty("token"), action)=>{
    if (action.type === "setSession"){
        localStorage.setItem("token", action.token)
        localStorage.setItem("mobile", action.mobile)
        state = true
        return state
    } else if (action.type === "getSession"){
        return localStorage.getItem("token")
    } else {
        return state
    }
}

export default reducer