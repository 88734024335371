import { useState } from "react";
import {Link, useLocation} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';

const Home = (props) => {

    const [amount, setamount] = useState("");
    let data = useLocation();

    let marketName = data.state[0].market;
    let is_open_status = data.state[0].is_open;
    let timing = data.state[0].timing;
    // function back(){
    //     let navigate = useNavigate();
    //     navigate(-1)
    // }


    return (<>
            <div className="RootView">
                <div className="RootWidth">

                    <div className="loginDiv">


                        <div className="toolbar">
                            <div>
                                <img src="../images/back.png" onClick={()=>{window.history.go(-1)}} style={{padding: "5px"}}/>
                                <span>Select Game</span>
                            </div>


                        </div>

                        <div >

                            <div className="games">
                            <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'single', timing:timing}]}>
                                    <div  className="gamediv">
                                        <div >
                                           <img src="../images/singlee.png" />
                                        </div>
                                        <span>Single Digits</span>
                                    </div>
                                </Link>

                               
                                <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'singlepanna', timing:timing}]}>
                                    <div  className="gamediv">
                                        <div >
                                        <img src="../images/ic_singkle_pana (1).png" />
                                        </div>
                                        <span>Single Pana</span>
                                    </div>
                                </Link>
                               
                            </div>

                            <div className="games">

                            <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'doublepanna', timing:timing}]}>
                                        <div  className="gamediv">
                                        <div >
                                        <img src="../images/ic_double_pana (1).png" />
                                        </div>
                                        <span>Double Pana</span>
                                    </div>
                                </Link>
                                <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'triplepanna', timing:timing}]}>
                                   <div  className="gamediv">
                                        <div >
                                        <img src="../images/ic_triple_pana (1).png" />
                                        </div>
                                        <span>Triple Pana</span>
                                    </div>
                                </Link>
                            
                            </div>


                        </div>

                    </div>


                </div>
            </div>


        </>
    );
};

export default Home;
