import React from "react";
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";


class Wallet extends React.Component {

    constructor() {
        super();
        this.state = {}
    }

    componentDidMount() {}

    render() {

        return (<>
                <div className="RootView">
                    <div className="RootWidth">

                        <div className="loginDiv">

                            <div className="toolbar">
                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>Winning History Selection</span>
                                </div>
                            </div>

                            <div style={{padding:"0px"}}>
                                <div className="transaction_block" style={{flexDirection:"column"}}>
                                    <div className="chartButton" style={{marginBottom:"10px"}}>
                                        <Link style={{width:"100%"}} to={"/winnings?type=main"}>
                                            <span>Main Matka</span>
                                        </Link>
                                    </div>

                                    <div className="chartButton" style={{marginBottom:"10px"}}>
                                        <Link style={{width:"100%"}}  to={"/winnings?type=starline"} state={{type:"starline"}}>
                                            <span>Starline Matka</span>
                                        </Link>
                                    </div>

                                    <div className="chartButton">
                                        <Link style={{width:"100%"}}  to={"/winnings?type=delhi"} state={{type:"delhi"}}>
                                            <span>Gali Disawar</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </>
        );
    }

}

export default Wallet;
