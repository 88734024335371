

export const setSession = (token, mobile)=> {
    return (dispatch)=>{
        dispatch({
            type:"setSession",
            token:token,
            mobile:mobile
        })
    }
}

export const getSession = ()=> {
    return (dispatch)=>{
        dispatch({
            type:"getSession"
        })
    }
}

export const isLogin = ()=> {
    return (dispatch)=>{
        dispatch({
            type:"isLogin"
        })
    }
}