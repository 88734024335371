import { useState } from "react";
import {Link, useLocation} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';

const Home = (props) => {

    const [amount, setamount] = useState("");
    let data = useLocation();

    let marketName = data.state[0].market;
    let is_open_status = data.state[0].is_open;
    // function back(){
    //     let navigate = useNavigate();
    //     navigate(-1)
    // }


    return (<>
            <div className="RootView">
                <div className="RootWidth">

                    <div className="loginDiv">


                        <div className="toolbar">
                            <div>
                                <img src="../images/back.png" onClick={()=>{window.history.go(-1)}} style={{padding: "5px"}}/>
                                <span>Select Game</span>
                            </div>


                        </div>

                        <div>

                            <div className="games">
                                <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'single'}]}>
                                    <div  className="gamediv">
                                        <div >
                                           <img src="../images/singlee.png" />
                                        </div>
                                        <span>Single Digits</span>
                                    </div>
                                </Link>

                                <Link to={"/oddeven"} state={[{market: marketName, is_open: is_open_status}]}>
                                    <div  className="gamediv">
                                        <div >
                                           <img src="../images/singlee.png" />
                                        </div>
                                        <span>Odd Even</span>
                                    </div>
                                </Link>

                              
                            </div>

                            <div className="games">

                                    <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'jodi'}]}>
                                        <div  className="gamediv">
                                            <div >
                                            <img src="../images/ic_jodi_digit (1).png" />
                                            </div>
                                            <span>Jodi Digits</span>
                                        </div>
                                    </Link>
                                    

                                    <Link to={"/redbracket"} state={[{market: marketName, is_open: is_open_status, game: 'jodi'}]}>
                                        <div  className="gamediv">
                                            <div >
                                            <img src="../images/ic_jodi_digit (1).png" />
                                            </div>
                                            <span>Red Bracket</span>
                                        </div>
                                    </Link>


                         
                            </div>



                        </div>

                    </div>


                </div>
            </div>


        </>
    );
};

export default Home;
