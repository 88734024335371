import {useCallback, useEffect, useState} from "react";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';

const Home = (props) => {

    const [amount, setamount] = useState("");
    let data = useLocation();

    if (data.state == null){
        window.location.href = "/";
    }
    let marketName = data.state[0].market;
    let is_open_status = data.state[0].is_open;
    // function back(){
    //     let navigate = useNavigate();
    //     navigate(-1)
    // }
    useEffect(() => {
        window.scrollTo({ top:0, left:0, behavior: "instant"})
    }, [])

    return (<>
            <div className="RootView">
                <div className="RootWidth">

                    <div className="loginDiv">


                        <div className="toolbar">
                            <div>
                                <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                <span>Select Game</span>
                            </div>


                        </div>

                        <div style={{ paddingBottom:"30px"}}>

                            <div className="games">
                                <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'single'}]}>
                                <img src="../images/a1.png" />
                                </Link>
{/* 
                                <Link to={"/oddeven"} state={[{market: marketName, is_open: is_open_status}]}>
                                <img src="../images/a1.png" />
                                </Link> */}

                              
                            </div>

                            { is_open_status === "1" ?
                                <div className="games">

                                    <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'jodi'}]}>
                                    <img src="../images/a2.png" />
                                    </Link>
                                    
                                    <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'singlepanna'}]}>
                                        <img src="../images/a3.png" />
                                     </Link>
                                    {/* <Link to={"/redbracket"} state={[{market: marketName, is_open: is_open_status, game: 'jodi'}]}>
                                        <img src="../images/a1.png" />
                                    </Link> */}

                               
                                </div>
                                : "" }

                            <div className="games">

                            

                                <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'doublepanna'}]}>
                                    <img src="../images/a4.png" />
                                </Link>
                              
                                <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'triplepanna'}]}>
                                <img src="../images/a5.png" />
                            </Link>
                            
                            </div>


                            
                            {/* <div className="games">
                             
                           
                            
                                <Link to={"/spdptp"} state={[{market: marketName, is_open: is_open_status, game: 'triplepanna'}]}>
                                    <div  className="gamediv">
                                        <div >
                                        <img src="../images/spdptp (1).png" />
                                        </div>
                                        <span>SP DP TP</span>
                                    </div>
                                </Link>
                            
                            </div> */}
{/* 
                            <div className="games">
                                <Link to={"/motor"} state={[{market: marketName, is_open: is_open_status, game: 'singlepanna'}]}>
                                    <div  className="gamediv">
                                        <div >
                                        <img src="../images/spmotor (1).png" />
                                        </div>
                                        <span>SP Motor</span>
                                    </div>
                                </Link>
                            
                                <Link to={"/motor"} state={[{market: marketName, is_open: is_open_status, game: 'doublepanna'}]}>
                                    <div  className="gamediv">
                                        <div >
                                        <img src="../images/dpmotor (1).png" />
                                        </div>
                                        <span>DP Motor</span>
                                    </div>
                                </Link>
                            </div> */}

                            { is_open_status === "1" ?
                                <div className="games">


                                <Link to={"/halfsangam"} state={[{market: marketName, is_open: is_open_status, game: 'halfsangam'}]}>
                                    <img src="../images/a6.png" />
                                </Link>
                            
                                <Link to={"/fullsangam"} state={[{market: marketName, is_open: is_open_status, game: 'fullsangam'}]}>
                                    <img src="../images/a7.png" />
                                </Link>

                                
                                </div>
                                : ""}

                        </div>

                    </div>


                </div>
            </div>


        </>
    );
};

export default Home;
